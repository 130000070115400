import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import InnerHero from '../components/InnerHero';
import Hero from '../images/contact--header@2x.jpg';
import JobApplicationForm from '../components/JobApplicationForm';
import JobDescriptionToggle from '../components/JobDescriptionToggle';

const Jobs = () => (
  <StaticQuery
    query={graphql`
      query JobsQuery {
        allContentfulJobs {
          nodes {
            title
            type
            location
            desc {
              content {
                content {
                  value
                }
              }
            }
            benefits {
              id
              content
            }
            duties {
              id
              content
            }
            id
            Salary
            isActive
          }
        }

        allContentfulCommonData {
          nodes {
            jobsSeoText {
              jobsSeoText
            }
          }
        }
      }
    `}

    render={(data) => {
      const graphqlData = data.allContentfulJobs.nodes;

      const [activeListing, activeListingSetter] = useState(null);

      return (
        <Layout>
          <SEO
            title="View All Jobs"
            lang="en"
            meta={[]}
          />

          <InnerHero image={Hero} tagline="Open Positions" />

          <div className="container--md">
            { activeListing && (
              <JobApplicationForm job={activeListing} activeListingSetter={activeListingSetter} />
            )}

            <section className="row">
              <div className="col-12">
                { graphqlData.map((job) => (
                  <section key={job.id} className={(job.isActive === false) ? 'hide' : 'job--container font--16 shadow--xs radius--sm margin--md padding--md'}>
                    <strong className="font--21">
                      {job.title}
                    </strong>
                    <br />
                    {job.type}
                    <br />
                    <span className="font--14">
                      {job.location}
                    </span>
                    <br />
                    <br />
                    <JobDescriptionToggle
                      benefits={job.benefits}
                      duties={job.duties}
                      desc={job.desc.content[0].content[0].value}
                      salary={job.Salary}
                    />

                    <button
                      type="button"
                      className="btn bg--upendoPrimary float--right radius--lg border--none font--16"
                      onClick={() => activeListingSetter(job)}
                    >
                      <strong>
                        {'Apply for Job'}
                      </strong>
                    </button>

                    <br />
                    <br />
                  </section>
                ))}
              </div>
            </section>
          </div>
          <div className="container--md font--12 padding--lg">
            {JSON.stringify(data.allContentfulCommonData.nodes[0].jobsSeoText.jobsSeoText)}
          </div>
          <br />
          <br />
        </Layout>
      );
    }}
  />
);

export default Jobs;
