import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const JobDescriptionToggle = ({
  duties = null,
  benefits = null,
  desc,
  salary,
}) => {
  const [showDesc, showDescSetter] = useState(false);

  return (
    <span>
      { (showDesc !== true) && (
        <button
          type="button"
          onClick={() => showDescSetter(!showDesc)}
          className="font--text font--12 radius--lg"
        >
          {'View Job Description '}
          <i className="fas fa-angle-down" />
        </button>
      )}

      <p className={(showDesc === true) ? 'font--text font--14 lh--md' : 'hide'}>
        <br />
        <strong className="font--18">
          {'Job Description:'}
        </strong>
        <div dangerouslySetInnerHTML={{ __html: desc }} />

        {/* duies */}
        { duties && duties.length > 0 && (
          <div>
            <br />
            <strong>Your Duties:</strong>
            <ul>
              {duties && duties.length > 0 && duties.map((d) => (
                <li key={`benefit--${d.content}`}>
                  &bull;
                  {' '}
                  {d.content}
                </li>
              ))}
            </ul>
            <br />
          </div>
        )}

        {/* benefits */}
        { benefits && benefits.length > 0 && (
          <div>
            <br />
            <strong>Benefits:</strong>
            <ul>
              {benefits && benefits.length > 0 && benefits.map((b) => (
                <li key={`benefit--${b.content}`}>
                  &bull;
                  {' '}
                  {b.content}
                </li>
              ))}
            </ul>
            <br />
          </div>
        )}

        {/* salary */}
        { salary && (
          <>
            <strong>
              {`Salary: ${salary}`}
            </strong>

            <br />
            <br />

          </>
        )}

        { (showDesc === true) && (
          <button
            type="button"
            onClick={() => showDescSetter(!showDesc)}
            className="font--text font--12 radius--lg"
          >
            {'Show Less '}
            <i className="fas fa-angle-up" />
          </button>
        )}
      </p>
    </span>
  );
};

JobDescriptionToggle.defaultProps = {
  salary: null,
};

JobDescriptionToggle.propTypes = {
  duties: PropTypes.object.isRequired,
  benefits: PropTypes.object.isRequired,
  desc: PropTypes.object.isRequired,
  salary: PropTypes.string,
};

export default JobDescriptionToggle;
