import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import {
  Input,
  TextArea,
  maskPhoneNumber,
  validateEmail,
} from '@nerds-with-charisma/nerds-style-sass';

const JobApplicationForm = ({ activeListingSetter, job }) => {
  const [isInvalid, isInvalidSetter] = useState(true);

  const [name, nameSetter] = useState(null);
  const [nameError, nameErrorSetter] = useState(null);
  const [email, emailSetter] = useState(null);
  const [emailError, emailErrorSetter] = useState(null);
  const [phone, phoneSetter] = useState(null);
  const [message, messageSetter] = useState(null);
  const [messageError, messageErrorSetter] = useState(null);

  const handleValidation = () => {
    let hasErrors = true;

    if (!name || name.length < 2) {
      nameErrorSetter('Enter a valid name');
      hasErrors = true;
    } else {
      nameErrorSetter(null);
      hasErrors = false;
    }

    if (!email || email.length < 2 || validateEmail(email).error === true) {
      emailErrorSetter('Enter a valid email');
      hasErrors = true;
    } else {
      emailErrorSetter(null);
      hasErrors = false;
    }

    if (!message || message.length < 2) {
      messageErrorSetter('Enter a valid message');
      hasErrors = true;
    } else {
      messageErrorSetter(null);
      hasErrors = false;
    }

    if (hasErrors === false) {
      isInvalidSetter(false);
    } else {
      isInvalidSetter(true);
    }
  };

  return (
    <div id="nwc--modal">
      <form
        id="JobApplicationForm"
        name="JobApplicationForm"
        data-netlify="true"
        method="post"
      >

        <input type="hidden" name="id" value={job.id} />
        <input type="hidden" name="title" value={job.title} />
        <input type="hidden" name="type" value={job.type} />
        <input type="hidden" name="location" value={job.location} />
        <input type="hidden" name="desc" value={job.desc.content[0].content[0].value} />

        <strong className="font--21">
          {job.title}
        </strong>
        <br />
        {job.type}
        <br />
        <span className="font--14">
          {job.location}
        </span>
        <br />
        <br />
        <p className="font--text font--14 lh--md">
          <div dangerouslySetInnerHTML={{ __html: job.desc.content[0].content[0].value }} />
        </p>
        <br />
        { job.Salary && (
          <strong>
            {`Salary: ${job.Salary}`}
          </strong>
        )}
        <br />
        <hr />
        <br />

        <Input
          title="Name"
          id="input--name"
          callback={(v) => nameSetter(v)}
          error={nameError}
          type="text"
          required
          populatedValue={name}
          blurCallback={handleValidation}
        />

        <br />
        <br />

        <input type="hidden" name="form-name" value="JobApplicationForm" />

        <Input
          title="Email"
          id="input--email"
          callback={(v) => emailSetter(v)}
          error={emailError}
          type="email"
          required
          populatedValue={email}
          blurCallback={handleValidation}
        />

        <br />
        <br />

        <Input
          title="Phone"
          id="input--maskedPhone"
          callback={(v) => {
            phoneSetter(maskPhoneNumber(v));
            document.getElementById('input--maskedPhone').value = maskPhoneNumber(v);
          }}
          type="tel"
          placeHolder="(555) 555-5555"
          maxLength="14"
          minLength="14"
          populatedValue={phone}
          blurCallback={handleValidation}
        />

        <br />
        <br />

        <TextArea
          title="Message"
          id="input--message"
          callback={(v) => messageSetter(v)}
          error={messageError}
          required
          populatedValue={message}
          rows="3"
          blurCallback={handleValidation}
        />

        <br />
        <br />

        <strong className="font--12">
          {'Upload Resume'}
        </strong>
        <label id="resumeUploadLabel" htmlFor="resumeUpload" className="border block radius--sm font--14">
          <i className="fas fa-cloud-upload-alt float--right font--upendoPrimary font--21 padding--md" />
          <input
            style={{ width: '70%' }}
            className="block padding--md"
            type="file"
            name="resumeUpload"
            id="resumeUpload"
            title="Upload Resume"
            onChange={() => {
              // if too big, remove it and alert them
              if (document.getElementById('resumeUpload').files[0].size > 1000000) {
                alert('Please keep your files under 1MB');
                document.getElementById('resumeUpload').value = '';
              }
            }}
          />
        </label>

        <br />
        <br />

        <button
          type="button"
          className="btn float--left radius--lg font--18 font--text"
          onClick={() => activeListingSetter(null)}
        >
          <strong>
            {'Cancel'}
          </strong>
        </button>

        <button
          type="submit"
          disabled={isInvalid}
          className="btn bg--upendoPrimary float--right radius--lg border--none font--18"
        >
          <strong className="hidden--xs hidden--sm">
            {'Apply for this Position'}
          </strong>

          <strong className="hidden--md hidden--lg">
            {'Apply'}
          </strong>
        </button>
        <br />
        <br />
      </form>
    </div>
  );
};

JobApplicationForm.propTypes = {
  activeListingSetter: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
};

export default JobApplicationForm;
